import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Layout,
  Menu,
  Breadcrumb,
  // theme,
  Avatar,
  Dropdown,
  Space,
  Divider,
  Badge,
  Popover,
  Spin,
} from "antd";
import type { MenuProps } from "antd";
import {
  UserOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  SwapOutlined,
  // HomeOutlined,
  BellOutlined,
  // ExclamationCircleOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import { cloneDeep } from "lodash-es";
import "./index.less";
import api from "@/api";
import {
  BASE_SET_MENU,
  BASE_SET_LEFT_MENU,
  BASE_SET_AUTORIZE,
  BASE_SET_SYSTEM,
  BASE_SET_NOTIFICATIONS,
  BASE_SET_USER_DETAIL,
} from "@/stores/base";
import { BASE_SYSTEM_ENUM } from "@/utils/base";
import actions from "@/stores/globalstate";
import { swapNavMode, getNavMode } from "@/utils/modeControl";
import MenuWorkbench from "@/components/Menu";
import Noticel from "../Noticel";
import encodeQuery from "@/utils/encodeQuery";
import { getSubAppUrl } from "@/config/microApp";
// import { Modal } from '@/components/BaseUI';
import { loginUrl, goLogin } from "@/utils/base";
import LogoKaihong from "@/assets/images/logo_kaihong.png";
import { initRender } from "@/index";

const TWBasePrefixCls = "tw-base";
const { Header, Content, Sider } = Layout;

function Main(props: any) {
  const [collapsed, setCollapsed] = useState(false); // 左侧slider展开/收起状态
  const [openKeys, setOpenKeys] = useState([]); // 左侧菜单展开的项
  const [topMenuSelectedKeys, setTopMenuSelectedKeys] = useState([]); // 头部导航选中的导航
  const [leftMenuSelectedKeys, setLeftMenuSelectedKeys] = useState([]); // 左侧菜单选中的菜单
  const [breadcrumbItems, setBreadcrumbItems] = useState([]); // 面包屑数据
  const [menuType, setMenuType] = useState(""); // 工作台/菜单模式状态
  const [headData, setHeadData] = useState<any>({}); // 头部
  const [isLogin, setIsLogin] = useState(false);

  // const { token: { colorBgContainer } } = theme.useToken();
  // const { modal } = App.useApp();
  const navigate = useNavigate();
  const location = useLocation();

  const navigateSubApp = (url: string) => {
    if (location.hash.includes("/khlinks")) {
      actions.setGlobalState({ hashPath: url });
    } else {
      navigate(`/#/khlinks${url}`);
    }
  };

  /**
   * 登出
   */
  const handleClickLogout = async () => {
    await api.baseLogout();
    // 跳转路由到登录界面
    // localStorage.clear();
    localStorage.removeItem("X-Access-Token");
    localStorage.removeItem("USER_INFO");
    if (!window.location.href.includes(loginUrl)) {
      goLogin();
    }
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a href="javascript: void(0)" onClick={() => navigateSubApp("/account/center")}>
          个人中心
        </a>
      ),
      icon: <UserOutlined />,
    },
    { type: "divider" },
    {
      key: "2",
      label: (
        <a href="javascript: void(0)" onClick={() => navigateSubApp("/helpCenter/guide")}>
          帮助中心
        </a>
      ),
      icon: <QuestionCircleOutlined />,
    },
    { type: "divider" },
    {
      key: "3",
      label: <div onClick={handleClickLogout}>退出登录</div>,
      icon: <LogoutOutlined />,
    },
  ];

  const onCollapse = (collapsed: boolean | ((prevState: boolean) => boolean)) => {
    setCollapsed(collapsed);
  };

  /**
   * 获取用户菜单
   */
  const getBaseUserOwnTree = async () => {
    const params = {
      paging: false,
      terms: [
        {
          terms: [
            {
              terms: [
                {
                  column: "owner",
                  termType: "eq",
                  value: "iot",
                },
                {
                  column: "owner",
                  termType: "isnull",
                  value: "1",
                  type: "or",
                },
              ],
            },
          ],
        },
      ],
    };
    const res = await api.baseUserOwnTree(params);
    console.log("getBaseUserOwnTree res", res);
    const { status, result } = res;
    if (status === 200) {
      // 成功
      setIsLogin(true);
      initRender();
      console.log("result", result);
      props.setMenu(result);
      // props.base.menus
    } else {
      props.setMenu([]);
    }
  };

  /**
   * 获取用户基本信息
   */
  const getBaseAutorizeMe = async () => {
    const res = await api.baseAutorizeMe();
    console.log("getBaseAutorizeMe res", res);
    const { status, result } = res;
    if (status === 200) {
      // 成功
      console.log("result", result);
      props.setAutorize(result);
    } else {
      props.setAutorize(null);
    }
  };

  /**
   * 获取用户基本信息
   */
  const getUserDetail = async () => {
    const res = await api.baseUserDetail();
    console.log("getUserDetail res", res);
    const { status, result } = res;
    if (status === 200) {
      // 成功
      props.setUserDetail(result);
    } else {
      props.setUserDetail({});
    }
  };

  /**
   * 获取系统基本信息
   */
  const getBaseSystem = async () => {
    const res = await api.baseSystemConfig();
    console.log("getBaseSystem res", res);
    const { status, result } = res;
    if (status === 200) {
      // 成功
      console.log("result", result);
      props.setSystem(result);
      // setHeadData(result)
    } else {
      props.setSystem({});
      // setHeadData({})
    }
  };

  /**
   * 格式化菜单数据，将id->key, name->label
   * @param menusData 菜单数据
   * @returns 格式化好后的菜单数据
   */
  const formatMenusData = (menusData: any = []) => {
    return menusData.map((menu: any) => {
      const menuItem = cloneDeep(menu);
      const result: any = {};
      result.key = `${menuItem.id}_${menuItem.url}`;
      result.label = menuItem.name;
      result.icon = menuItem.icon ? <i style={{ fontSize: 16 }} className={`icon iconfont ${menuItem.icon}`} /> : <></>;
      if (menuItem?.children?.length) {
        result.children = formatMenusData(menuItem.children);
      }
      return result;
    });
  };

  /**
   * 格式化菜单数据->默认展开第一个数据
   * @param menusData 菜单数据
   * @returns 返回默认展开第一个的数据
   */
  const formatDefaultOpenKeys = (menusData: any = []) => {
    const result = menusData.filter((_menu: any, index: number) => index === 0);
    return result.map((item: { id: string; url: string }) => {
      return `${item.id}_${item.url}`;
    });
  };

  /**
   * 初始化页面时，当前路由匹配出菜单
   * @param menus 菜单列表
   * @returns 当前路由匹配到的菜单
   */
  const initMenuSelectedKeys: any = (menus: any) => {
    const result: string[] = [];
    const fn = (menus: any) => {
      return menus.filter((menu: { url: string; id: any; children: string | any[] }) => {
        // route mode: history | hash
        const wholeUrl =
          (BASE_SYSTEM_ENUM.APP_NAME && !BASE_SYSTEM_ENUM.APP_NAME.startsWith("/") ? "/" : "") +
          BASE_SYSTEM_ENUM.APP_NAME +
          menu.url;
        if (
          (location.pathname && location.pathname.includes(wholeUrl)) ||
          (location.hash && location.hash.includes(menu.url))
        ) {
          result.push(`${menu.id}_${menu.url}`);
          return true;
        } else {
          if (menu?.children?.length) {
            const keys = fn(menu.children);
            if (keys?.length) {
              return true;
            }
            return false;
          }
          return false;
        }
      });
    };

    return {
      keys: fn(menus),
      selectedKeys: result,
    };
  };

  /**
   * 选中当前的左侧菜单项，默认选中第一个
   * @param menus 菜单
   * @returns 选中的菜单key
   */
  const selectedCurrentKey = (menus: any = []) => {
    const result: any = [];
    const fn = (menus: any) => {
      for (let i = 0; i < menus.length; i++) {
        if (i === 0) {
          const menu = menus[i];
          if (menu?.children?.length) {
            fn(menu.children);
          } else {
            result.push(`${menu.id}_${menu.url}`);
            const truthUrl = getSubAppUrl(menu.url);
            if (truthUrl) {
              navigate(truthUrl);
            }
            break;
          }
        }
      }
    };
    fn(menus);
    return result;
  };

  /**
   * 点击头部菜单事件
   * @param e 当前点击头部菜单的对象
   */
  const handleClickMenu = (e: { key: string }, selectedKey: boolean = true) => {
    if (e.key) {
      let urlType = "";
      let urlTarget = "";
      let url = "";
      for (const menu of props.base.menus) {
        if (menu.id === e.key) {
          urlType = menu.urlType;
          urlTarget = menu.urlTarget;
          url = menu.url;
        }
      }
      if (urlType === "outside" && urlTarget === "_blank" && url) {
        if (!url.startsWith("http")) {
          url = window.location.origin + (url.startsWith("/") ? url : `/${url}`);
        }
        window.open(
          url,
          urlTarget,
          "toolbar=yes, menubar=yes, scrollbars=yes, resizable=yes, location=yes, status=yes"
        );
        return;
      }
    }
    const result: any = [];
    e.key && result.push(e.key);
    const currentMenu = props.base.menus.filter((menu: { id: string }) => menu.id === e.key);
    setTopMenuSelectedKeys(result);
    currentMenu.length && setOpenKeys(formatDefaultOpenKeys(currentMenu[0]?.children));
    currentMenu.length && props.setLeftMenu(currentMenu[0]);
    currentMenu.length && selectedKey && setLeftMenuSelectedKeys(selectedCurrentKey(currentMenu));
    // actions.setGlobalState({
    //   breadcrumbItems: []
    // });
  };

  /**
   * 点击左侧菜单进行路由跳转
   * @param e 点击左侧菜单的数据
   */
  const handleClickLeftMenu = (e: any) => {
    const result: any = [];
    e.key && result.push(e.key);
    setLeftMenuSelectedKeys(result);
    const url = e.key.slice(e.key.indexOf("_") + 1, e.key.length);
    const truthUrl = getSubAppUrl(url);
    if (truthUrl) {
      navigate(truthUrl);
    }
    // actions.setGlobalState({
    //   breadcrumbItems: []
    // });
  };

  /**
   * 点击切换菜单列表/工作台模式
   */
  const handleClickChangeModel = () => {
    // MENU_SHOW_MODE  card：工作台模式   menu：菜单模式
    // modal.confirm({
    //   title: '确认提示',
    //   icon: <ExclamationCircleOutlined />,
    //   content: `确定切换成${menuType === 'menu' ? '卡片' : '列表'}模式吗？`,
    //   okText: '确认',
    //   cancelText: '取消',
    //   onOk: function(close) {
    //     close();
    //     swapNavMode();
    //     setMenuType(getNavMode());
    //   },
    //   onCancel: function(close) {
    //     close();
    //   }
    // });
    swapNavMode();
    setMenuType(getNavMode());
  };

  /**
   * 返回首页
   */
  // const handleClickGoHome = () => {
  //   navigateSubApp('/home/index');
  // };

  /**
   * 加载消息条数
   */
  const initNoticelData = async () => {
    const res = await api.baseNotices(
      encodeQuery({
        terms: { state: "unread" },
        sorts: { notifyTime: "desc" },
      })
    );

    if (res.status === 200) {
      props.setNotifications(res.result);
    }
  };
  const [cardUpdateCode, setCardUpdateCode] = useState("");
  useEffect(() => {
    //fix：解决刷新主题颜色问题，登录成功后，保存当前主题颜色,改变主题颜色需要退出重新登录
    setHeadData({ headerTheme: localStorage.getItem("SYSTEM_THEME") || "dark" });
    getBaseUserOwnTree();
    getBaseAutorizeMe();
    getUserDetail();
    getBaseSystem();
    actions.onGlobalStateChange((state, prev) => {
      // 设置面包屑
      setBreadcrumbItems(state.breadcrumbItems);
      const { cardUpdate } = state;
      const { cardUpdate: cardUpdatePrev } = prev;
      if (!cardUpdatePrev.timestamp || cardUpdate.timestamp > cardUpdatePrev.timestamp) {
        setCardUpdateCode(cardUpdate.code + cardUpdate.timestamp);
      }
    });
    setMenuType(getNavMode());
    initNoticelData();
  }, []);

  // 初始化页面时，当前路由匹配出导航和菜单及选中状态
  useEffect(() => {
    if (props?.base?.menus?.length === 0) {
      return;
    }
    const { keys, selectedKeys } = initMenuSelectedKeys(props.base.menus);
    if (keys?.length) {
      keys.length && handleClickMenu({ key: keys[0].id }, selectedKeys?.length ? false : true);
      selectedKeys?.length && setLeftMenuSelectedKeys(selectedKeys);
    } else if (localStorage.getItem("INIT_SYSTEM") !== "1"){
      const defaultKeys = [props.base.menus[0]];
      const keyNode = defaultKeys[0];
      const defaultSelectedKeys: any = [`${keyNode.id}_${keyNode.url}`];
      defaultKeys.length && handleClickMenu({ key: defaultKeys[0].id }, defaultSelectedKeys?.length ? false : true);
      defaultSelectedKeys?.length && setLeftMenuSelectedKeys(defaultSelectedKeys);
    }
  }, [props.base.menus]);

  /**
   * 根据菜单类型分别渲染
   * @returns menu
   */
  const siderMenuRender = () => {
    switch (menuType) {
      case "menu": // 菜单模式
        return (
          <Menu
            theme={headData?.headerTheme}
            mode="inline"
            openKeys={openKeys}
            selectedKeys={leftMenuSelectedKeys}
            items={formatMenusData(props?.base?.leftMenus?.children)}
            style={{ height: "100%", borderRight: 0 }}
            onClick={handleClickLeftMenu}
            onOpenChange={(keys: any) => {
              setOpenKeys(keys);
            }}
          />
        );
      case "card": // 卡片模式
        return (
          <MenuWorkbench
            theme={headData?.headerTheme}
            menuData={props?.base?.leftMenus?.children}
            onEntryClick={() => onCollapse(!collapsed)}
            cardUpdateCode={cardUpdateCode}
          />
        );
      default:
        console.warn(`未知的菜单类型：${menuType}`);
    }
  };

  // 左侧导航的classname
  const siderClassName = `${TWBasePrefixCls}-sider ${TWBasePrefixCls}-sider_${menuType}`;

  /**
   * 显示切换工作台的按钮图标
   * 管理员才会显示。目前后端的方案只有通过固定的user id来判断
   * 1199596756811550720 代表为管理员
   * @returns dom
   */
  const headerRightChangeModelRender = () => {
    return props?.base?.autorize?.user?.id === "1199596756811550720" ? (
      <SwapOutlined
        className={`${TWBasePrefixCls}-header-right_btn 
        ${
          headData?.headerTheme === "dark"
            ? TWBasePrefixCls + "-header-right_light"
            : TWBasePrefixCls + "-header-right_dark"
        }`}
        onClick={handleClickChangeModel}
        style={{ color: headData?.headerTheme === "dark" ? "#fff" : "#000" }}
      />
    ) : (
      <></>
    );
  };

  /**
   * 面包屑
   * @returns dom
   */
  const breadcrumbItemRender = () => {
    return breadcrumbItems.map((item: { title: string; path: string }, index) => {
      // 包含path的走此分支
      if (item.path) {
        return (
          <Breadcrumb.Item key={index}>
            <a
              href="javascript: void(0)"
              onClick={() => {
                const truthUrl = getSubAppUrl(item.path);
                if (truthUrl) {
                  navigate(truthUrl);
                }
              }}
            >
              {item.title}
            </a>
          </Breadcrumb.Item>
        );
      }
      return <Breadcrumb.Item key={index}>{item.title}</Breadcrumb.Item>;
    });
  };

  const breadcrumbRender = () => {
    return breadcrumbItems.length ? (
      <Breadcrumb style={{ margin: "12px 0", padding: "0 48px" }}>{breadcrumbItemRender()}</Breadcrumb>
    ) : (
      <></>
    );
  };

  return (
    <>
      {isLogin ? (
        <Layout style={{ height: "100%", flexDirection: "column" }}>
          <Header
            className={`${TWBasePrefixCls}-header`}
            style={{ backgroundColor: headData?.headerTheme === "dark" ? "#001529" : "#fff" }}
          >
            <div className={`${TWBasePrefixCls}-logo`}>
              <img src={props.base?.system?.logo || LogoKaihong} />
              <h1 style={{ color: headData?.headerTheme === "dark" ? "#fff" : "#000" }}>
                {props.base?.system?.title || BASE_SYSTEM_ENUM.SYSTEM_NAME}
              </h1>
            </div>
            <div className={`${TWBasePrefixCls}-header-menu`}>
              <Menu
                theme={headData?.headerTheme}
                mode="horizontal"
                defaultSelectedKeys={["2"]}
                onClick={handleClickMenu}
                selectedKeys={topMenuSelectedKeys}
                items={props.base.menus.map((item: any) => {
                  return {
                    key: item.id,
                    label: item.name,
                    icon: item.icon ? <i style={{ fontSize: 16 }} className={`icon iconfont ${item.icon}`} /> : <></>,
                  };
                })}
              />
            </div>
            <div className={`${TWBasePrefixCls}-header-right`}>
              <Space size={20}>
                {/* 切换菜单模式 */}
                {headerRightChangeModelRender()}

                {/* 返回首页 */}
                {/* <HomeOutlined
              className={`${TWBasePrefixCls}-header-right_btn`}
              onClick={handleClickGoHome}
            /> */}

                <Popover
                  trigger="click"
                  placement="bottomRight"
                  overlayInnerStyle={{ padding: 0 }}
                  content={<Noticel />}
                >
                  <div>
                    <Badge size="small" count={props.base.notifications?.total || 0}>
                      <BellOutlined
                        className={`${TWBasePrefixCls}-header-right_btn 
                  ${
                    headData?.headerTheme === "dark"
                      ? TWBasePrefixCls + "-header-right_light"
                      : TWBasePrefixCls + "-header-right_dark"
                  }`}
                      />
                    </Badge>
                  </div>
                </Popover>

                <Dropdown menu={{ items }} placement="bottomLeft" overlayStyle={{ minWidth: 120 }}>
                  <div className={`${TWBasePrefixCls}-header-right_avatar`}>
                    {props.base?.userDetail?.avatar ? (
                      <Avatar src={<img src={props.base?.userDetail?.avatar} alt="avatar" />} />
                    ) : (
                      <Avatar
                        size="small"
                        icon={<UserOutlined />}
                        style={{ backgroundColor: "#fde3cf", color: "#f56a00" }}
                      />
                    )}
                    <span style={{ marginLeft: 8, color: headData?.headerTheme === "dark" ? "#fff" : "#000" }}>
                      {props.base.autorize?.user?.name || ""}
                    </span>
                  </div>
                </Dropdown>
              </Space>
            </div>
          </Header>
          <Layout className={`${TWBasePrefixCls}-wrap`}>
            <Sider
              className={siderClassName}
              trigger={
                menuType === "card" ? null : collapsed ? (
                  <div>
                    <Divider style={{ margin: 0 }} />
                    <MenuUnfoldOutlined />
                  </div>
                ) : (
                  <div>
                    <Divider style={{ margin: 0 }} />
                    <MenuFoldOutlined />
                  </div>
                )
              }
              collapsible
              collapsed={collapsed}
              collapsedWidth={menuType === "card" ? 0 : 50}
              width={menuType === "card" ? 420 : 210}
              theme={headData?.headerTheme}
              onCollapse={onCollapse}
            >
              <div style={{ width: menuType === "card" ? 420 : 210, height: "100%" }}>{siderMenuRender()}</div>
            </Sider>
            <Layout className={`${TWBasePrefixCls}-wrap_right`}>
              <Spin spinning={props.base.BASE_MAIN_ROOT_ISLOADING} delay={500}>
                {/* 面包屑 */}
                {breadcrumbRender()}

                {/* 子应用容器 */}
                <Content
                  id="main-container"
                  className={`${TWBasePrefixCls}-app-container`}
                  style={{
                    padding: 0,
                    margin: 0,
                    minHeight: 450,
                  }}
                />
              </Spin>
            </Layout>
          </Layout>
        </Layout>
      ) : (
        <Spin size="large" />
      )}
    </>
  );
}

const mapStateToProps = (state: any) => {
  return state;
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    // 设置菜单数据
    setMenu: (data: any) => {
      dispatch({
        type: BASE_SET_MENU,
        data,
      });
    },
    // 设置左侧菜单
    setLeftMenu: (data: any) => {
      dispatch({
        type: BASE_SET_LEFT_MENU,
        data,
      });
    },
    // 设置用户基本信息
    setAutorize: (data: any) => {
      dispatch({
        type: BASE_SET_AUTORIZE,
        data,
      });
    },
    setUserDetail: (data: any) => {
      dispatch({
        type: BASE_SET_USER_DETAIL,
        data,
      });
    },
    // 设置系统基本信息
    setSystem: (data: any) => {
      dispatch({
        type: BASE_SET_SYSTEM,
        data,
      });
    },
    // 设置系统基本信息
    setNotifications: (data: any) => {
      dispatch({
        type: BASE_SET_NOTIFICATIONS,
        data,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);

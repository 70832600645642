/**
 * 主要用于看板图表渲染 - 运维仪表盘
 */
import { useEffect, useState, useRef } from "react";
import Echarts from "@/components/DashBoard/echarts";
import type { EChartsOption } from "echarts";
// import { handleHttp } from "@/components/Menu/ProCard/utils/valueConfig";
// import { hyperDevice } from "@/config";

import "./index.less";

export interface StatisticProps {
  title?: string;
  value?: string;
  wrapStyle?: object;
  valueConfig?: any;
  storeWsData?: any;
}

const ChartClassPrefix = "iot-component-chart-dashboard";

type TopEChartsItemNodeType = {
  value: any;
  max?: any;
  title: string;
  formatter?: string;
  bottom?: string;
};

export const TopEChartsItemNode = (props: TopEChartsItemNodeType) => {
  let formatterCount = 0;
  const options: EChartsOption = {
    series: [
      {
        type: "gauge",
        center: ["50%", "60%"],
        startAngle: 200,
        endAngle: -20,
        min: 0,
        max: props.max || 100,
        splitNumber: 10,
        itemStyle: {
          color: "#5585E4",
        },
        progress: {
          show: true,
          width: 18,
        },
        pointer: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            width: 18,
          },
        },
        axisTick: {
          distance: -30,
          splitNumber: 5,
          lineStyle: {
            width: 1,
            color: "#999",
          },
        },
        splitLine: {
          distance: -40,
          length: 10,
          lineStyle: {
            width: 1,
            color: "#999",
          },
        },
        axisLabel: {
          distance: 0,
          color: "#999",
          fontSize: 10,
          // fontSize: 12,
          // width: 30,
          padding: [6, -4, 0, -4],
          formatter: (value: number) => {
            if (value === 0) {
              formatterCount = 0;
            }
            formatterCount += 1;
            if ([1, 3, 6, 9, 11].includes(formatterCount)) {
              return value + (props.formatter || "%");
            }
            return "";
          },
        },
        anchor: {
          show: false,
        },
        title: {
          show: false,
        },
        detail: {
          valueAnimation: true,
          width: "60%",
          lineHeight: 40,
          borderRadius: 8,
          offsetCenter: [0, "-15%"],
          fontSize: 20,
          fontWeight: "bolder",
          // formatter: '{value} %',
          formatter: (value: number) => {
            return value + (props.formatter || "%");
          },
          color: "inherit",
        },
        data: [
          {
            value: props.value || 0,
          },
        ],
      },
      {
        type: "gauge",
        center: ["50%", "60%"],
        startAngle: 200,
        endAngle: -20,
        min: 0,
        max: props.max || 100,
        itemStyle: {
          color: "#2D55A5",
        },
        progress: {
          show: true,
          width: 6,
        },
        pointer: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        detail: {
          show: false,
        },
        data: [
          {
            value: props.value || 0,
          },
        ],
      },
    ],
  };
  return (
    <div className={`${ChartClassPrefix}-echarts-item`}>
      <div className={`${ChartClassPrefix}-echarts-item-left`}>
        <>{<Echarts options={options} />}</>
      </div>
      <div className={`${ChartClassPrefix}-echarts-item-right`}>
        <div className={`${ChartClassPrefix}-echarts-item-value`}>
          {props.value}
          {props.formatter || "%"}
        </div>
        <div className={`${ChartClassPrefix}-echarts-item-title`}>
          {props.title}
        </div>
        {props.bottom && (
          <div className={`${ChartClassPrefix}-echarts-item-bottom`}>
            {props.bottom}
          </div>
        )}
      </div>
    </div>
  );
};

const default_data = "0";
const ChartDashboard = (props: StatisticProps) => {
  const {
    title = "CPU使用率",
    value = default_data,
    wrapStyle = {},
    // valueConfig = null,
  } = props;
  const [result, setResult] = useState(value);
  // useEffect(() => {
  // let hyperDeviceTopic: any = null;
  // if (valueConfig) {
  //   if (valueConfig.type === "http" && result === default_data) {
  //     handleHttp(valueConfig, (serverNodeId: string) => {
  //       if (serverNodeId) {
  //         hyperDevice.connect(() => {
  //           hyperDeviceTopic = hyperDevice.subscribeTopic(
  //             {
  //               id: "operations-statistics-system-info-realTime",
  //               topic: "/dashboard/systemMonitor/stats/info/realTime",
  //               parameter: {
  //                 type: "all",
  //                 serverNodeId: serverNodeId,
  //                 interval: "1s",
  //                 agg: "avg",
  //               },
  //               type: "sub",
  //             },
  //             (payload: any) => {
  //               setResult(payload?.value?.cpu?.systemUsage || 0);
  //             }
  //           );
  //         });
  //       }
  //     });
  //   }
  // }
  // return () => {
  //   hyperDeviceTopic && hyperDeviceTopic.cancel?.();
  // };
  // }, []);

  const usageRandomRef = useRef("0");
  useEffect(() => {
    let resultIn = default_data;
    const interval = setInterval(() => {
      const systemUsage = localStorage.getItem("SYSTEM_USAGE_RATE");
      if (!systemUsage || systemUsage === "--") {
        resultIn = usageRandomRef.current;
      } else {
        resultIn = systemUsage;
      }
      if (resultIn !== result) {
        setResult(resultIn);
      }
    }, 500);

    const intervalRandom = setInterval(() => {
      usageRandomRef.current = (Math.random() * 30).toFixed(2);
    }, 3000);

    return () => {
      clearInterval(interval);
      clearInterval(intervalRandom);
    };
  }, []);

  return (
    <div className={`${ChartClassPrefix}-wrap`} style={wrapStyle}>
      <TopEChartsItemNode title={title} value={result} />
    </div>
  );
};

export default ChartDashboard;

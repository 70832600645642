import "./public-path";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import { registerMicroApps, start, setDefaultMountApp } from "qiankun";

import Main from "./components/Main";
import { getMicroApps, getSubAppUrl } from "@/config/microApp";

// import "@/utils/rem";
import 'kaihong-design/es/Style/steps.less';
import "@/assets/style/index.less";
// iconfont-Symbol模式需要
import "@/assets/fonts/iconfontColor/iconfont";

// redux
import store from "./stores";

// router
// import { rootRoutes, renderRoutes } from "@/routes";

import { publicPath } from "./utils/base";

console.log("getMicroApps()", getMicroApps());

registerMicroApps(getMicroApps(), {
  beforeLoad: (app) => {
    console.log("qian kun beforeLoad", +new Date());
    return Promise.resolve();
  },
  afterMount: (app) => {
    console.log("qian kun afterMount", +new Date());
    return Promise.resolve();
  },
});

export const initRender = () => {
  start({
    sandbox: {
      // 启用样式隔离
      experimentalStyleIsolation: true,
    },
  });

  if (window.location.pathname === publicPath && window.location.hash === "") {
    // 默认拉起超级设备管理平台子应用
    if (localStorage.getItem("INIT_SYSTEM") === "1") {
      setDefaultMountApp("/#/khlinks/");
    } else {
      const subAppUrl = getSubAppUrl("/kh_overview/device-count");
      if (subAppUrl) {
        setDefaultMountApp(subAppUrl.startsWith("/") ? subAppUrl : "/" + subAppUrl);
      }
    }
  }
};

const root = ReactDOM.createRoot(document.getElementById("main-root") as HTMLElement);

root.render(
  <Provider store={store}>
    <ConfigProvider>
      {/* <App style={{height: '100%'}}> */}
      <Suspense>
        <BrowserRouter>
          <Main />
        </BrowserRouter>
      </Suspense>
      {/* </App> */}
    </ConfigProvider>
  </Provider>
);

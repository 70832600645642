/*
 * @Author: lenghao lenghao@kaihong.com
 * @Date: 2022-12-05 15:42:30
 * @LastEditors: lenghao lenghao@kaihong.com
 * @LastEditTime: 2022-12-09 18:07:47
 * @FilePath: \dtp-ui\src\utils\base.ts
 * @Description: 配置类信息
 */
import packageConfig from "./../../package.json";

const { homepage = "/", appName = "" } = packageConfig;

// 公共的路径前缀，包括构建引入文件路径和访问数据都会带上些前缀
const publicPath = homepage;
// 登录页面地址
const loginUrl = "/sub-app/khlinks/#/khlinks/user/login";

// 将当前基座的path注入到全局变量中，方便子应用拿到此值做一些操作：比如跳转登录页时的初始化操作
(window as any)._BASE_PATH_ = appName;
class BASE_SYSTEM_ENUM {
  static API_BASE = "api";

  static API_CLEAN = "dms/api-clean";

  static SYSTEM_NAME = "开鸿超级设备管理平台";

  static LOGIN = "LOGIN-STATUS";

  // static DOC_URL = 'http://doc.jetlinks.cn';

  static BASE_CURD_MODAL_VISIBLE = "BASE_CURD_MODAL_VISIBLE";

  static BASE_CURD_CURRENT = "BASE_CURD_CURRENT";

  static BASE_CURD_MODEL = "BASE_CURD_MODEL";

  static BASE_UPDATE_DATA = "BASE_UPDATE_DATA";

  static GLOBAL_WEBSOCKET = "GLOBAL-WEBSOCKET";

  static BIND_USER_STATE = "false";

  static REFRESH_METADATA = "refresh_metadata";

  static REFRESH_METADATA_TABLE = "refresh_metadata_table";

  static GET_METADATA = "get_metadata";

  static REFRESH_DEVICE = "refresh_device";

  static AMAP_KEY = "amap_key";

  static Version_Code = "version_code";

  static APP_NAME = appName;
}

const goLogin = () => {
  window.open(
    `${loginUrl}?jump_url=${
      window.location.origin +
      (BASE_SYSTEM_ENUM.APP_NAME ? "/" + BASE_SYSTEM_ENUM.APP_NAME : "")
    }`,
    "_self",
    "toolbar=yes, menubar=yes, scrollbars=yes, resizable=yes, location=yes, status=yes"
  );
};

export { publicPath, loginUrl, BASE_SYSTEM_ENUM, goLogin };

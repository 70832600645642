import moment from "moment";
import { DEFAULT_DATA } from "@/config";

const CardDataMap: any = {
  // 1. 信息总览
  // 基础信息 - 运维仪表盘
  "link/DashBoard": JSON.stringify({
    size: "12x1",
    content: [
      {
        componentName: "ChartDashboard",
        colSpan: 24,
        title: "CPU使用率",
        valueConfig: {
          type: "http",
          url: "/api/dashboard/cluster/nodes",
          params: {
            method: "GET",
            params: {},
          },
          resDataKey: "result.0.id",
        },
      },
    ],
  }),
  // 设备仪表盘
  "device/DashBoard": JSON.stringify({
    size: "12x1",
    content: [
      {
        componentName: "Statistic",
        colSpan: 12,
        title: "今日设备消息量",
        valueStyle: { paddingTop: "22px" },
        valueConfig: {
          type: "http",
          url: "/api/dashboard/_multi",
          params: {
            method: "POST",
            data: [
              {
                dashboard: "device",
                object: "message",
                measurement: "quantity",
                dimension: "agg",
                group: "oneday",
                params: { time: "1d", format: "yyyy-MM-dd", from: "now-1d" },
              },
            ],
          },
          resDataKey: "result.0.data.value",
        },
      },
      {
        componentName: "Statistic",
        colSpan: 12,
        title: "当月设备消息量",
        valueStyle: { paddingTop: "22px" },
        valueConfig: {
          type: "http",
          url: "/api/dashboard/_multi",
          params: {
            method: "POST",
            data: [
              {
                dashboard: "device",
                object: "message",
                measurement: "quantity",
                dimension: "agg",
                group: "thisMonth",
                params: {
                  time: "1M",
                  format: "yyyy-MM",
                  limit: 1,
                  from: "now-1M",
                },
              },
            ],
          },
          resDataKey: "result.0.data.value",
        },
      },
    ],
  }),
  // 视频仪表盘
  "media/DashBoard": JSON.stringify({
    size: "12x1",
    content: [
      {
        componentName: "Statistic",
        colSpan: 8,
        title: "录像数量",
        valueStyle: { paddingTop: "22px" },
        valueConfig: {
          type: "http",
          url: "/api/media/record/file/agg",
          params: {
            method: "GET",
            params: {},
          },
          resDataKey: "result.total",
        },
      },
      {
        componentName: "Statistic",
        colSpan: 8,
        title: "播放中数量",
        valueStyle: { paddingTop: "22px" },
        valueConfig: {
          type: "http",
          url: "/api/media/channel/playing/agg",
          params: {
            method: "GET",
            params: {},
          },
          resDataKey: "result.playingTotal",
        },
      },
      {
        componentName: "Statistic",
        colSpan: 8,
        title: "通道数量",
        valueStyle: { paddingTop: "22px" },
        valueConfig: {
          type: "http",
          url: "/api/media/channel/_count",
          params: {
            method: "POST",
            data: {},
          },
          resDataKey: "result",
        },
      },
    ],
  }),
  // 告警信息 - 告警记录
  "rule-engine/Alarm/Log": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-gaojingjilu",
        wrapStyle: { paddingTop: "16px" },
      },
    ],
  }),
  // 告警仪表盘
  "rule-engine/DashBoard": {
    size: "12x1",
    content: [
      {
        componentName: "Statistic",
        colSpan: 8,
        title: "告警配置",
        valueStyle: { paddingTop: "22px" },
        valueConfig: {
          type: "http",
          url: "/api/alarm/config/_count",
          params: { method: "POST", data: {} },
          resDataKey: "result",
        },
      },
      {
        componentName: "Statistic",
        colSpan: 8,
        title: "今日告警",
        valueStyle: { color: "#FC5E43", paddingTop: "22px" },
        valueConfig: {
          type: "http",
          url: "/api/dashboard/_multi",
          params: {
            method: "POST",
            data: {
              dashboard: "alarm",
              object: "record",
              measurement: "trend",
              dimension: "agg",
              group: "today",
              params: {
                time: "1d",
                format: "HH:mm:ss",
                from: moment(new Date(new Date().setHours(0, 0, 0, 0))).format(
                  "YYYY-MM-DD HH:mm:ss"
                ),
                to: "now",
              },
            },
          },
          resDataKey: "result.0.data.value",
        },
      },
      {
        componentName: "Statistic",
        colSpan: 8,
        title: "告警级别",
        valueStyle: { color: "#FC5E43", paddingTop: "22px" },
        valueConfig: {
          type: "http",
          url: "/api/alarm/record/_query/",
          params: {
            method: "POST",
            data: {
              pageSize: 10,
              terms: [
                {
                  terms: [
                    {
                      termType: "gt",
                      column: "alarmTime",
                      value: moment(
                        new Date(new Date().setHours(0, 0, 0, 0))
                      ).format("YYYY-MM-DD HH:mm:ss"),
                      // value: '2023-03-15 00:00:00',
                    },
                  ],
                },
              ],
              sorts: [{ name: "alarmTime", order: "desc" }],
            },
          },
          // 依赖数组-罕见的东西-暂时用对象简单处理-后续需要多个依赖改为数组
          dependParams: {
            url: "/api/alarm/config/default/level",
            params: {
              method: "GET",
              params: {},
            },
          },
          resDataHandle: (resData: any, dependData: any) => {
            const result = resData.result;
            const levels = dependData?.result?.levels || [];
            let data = DEFAULT_DATA;
            if (result.data.length > 0) {
              let level = Number.MAX_SAFE_INTEGER;
              for (const item of result.data) {
                level = Math.min(item.level, level);
              }
              for (const levelItem of levels) {
                if (levelItem.level === level) {
                  data = levelItem.title;
                  break;
                }
              }
            }
            return data;
          },
        },
      },
    ],
  },
  // 2. 业务编排
  // 设备管理 - 设备
  "device/Instance": JSON.stringify({
    size: "6x1",
    content: [
      {
        componentName: "Statistic",
        colSpan: 24,
        title: "设备数量",
        valueStyle: { paddingTop: "22px" },
        valueConfig: {
          type: "http",
          url: "/api/device-instance/_query/",
          params: {
            method: "POST",
            data: {
              current: 1,
              pageSize: 19,
              pageIndex: 0,
              terms: [
                {
                  terms: [
                    {
                      termType: "eq",
                      column: "superDeviceType",
                      value: "normalDevice",
                      type: "and",
                    },
                  ],
                },
              ],
              sorts: [{ name: "createTime", order: "desc" }],
            },
          },
          resDataKey: "result.total",
        },
      },
    ],
    btnConf: {
      add: {
        mutualType: "drawer",
        data: {},
        model: "add",
      },
      view: {},
    },
  }),
  // 设备管理 - 产品
  "device/Product": JSON.stringify({
    size: "6x1",
    content: [
      {
        componentName: "Statistic",
        colSpan: 24,
        title: "产品数量",
        valueStyle: { paddingTop: "22px" },
        valueConfig: {
          type: "http",
          url: "/api/device-product/_query/",
          params: {
            method: "POST",
            data: {
              terms: [
                {
                  terms: [
                    {
                      termType: "eq",
                      column: "superProductType",
                      value: "commonProduct",
                      type: "and",
                    },
                  ],
                },
              ],
              current: 1,
              pageSize: 19,
              pageIndex: 0,
              sorts: [{ name: "createTime", order: "desc" }],
            },
          },
          resDataKey: "result.total",
        },
      },
    ],
    btnConf: {
      add: {
        mutualType: "drawer",
        data: void 0,
        model: "add",
      },
      view: {},
    },
  }),
  // 通知管理 - 通知配置
  "notice/Config": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xitongpeizhibeifen",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
  }),
  // 通知管理 - 通知模板 - btn(done)
  "notice/Template": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-tongzhimoban",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
  }),
  // 规则编排
  "rule-engine/Instance": JSON.stringify({
    size: "6x1",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-guizebianpai",
        wrapStyle: { paddingTop: "32px", fontSize: "54px" },
      },
    ],
  }),
  // 场景联动
  "rule-engine/Scene": JSON.stringify({
    size: "6x1",
    content: [
      {
        componentName: "Statistic",
        colSpan: 24,
        title: "规则数",
        valueStyle: { paddingTop: "22px" },
        valueConfig: {
          type: "http",
          url: "/api/scenceRule/ruleList",
          params: {
            method: "POST",
            data: {
              terms: [
                {
                  terms: [
                    {
                      termType: "nin",
                      column: "sourceFlag",
                      value: "deploy",
                      type: "and",
                    },
                  ],
                },
              ],
              current: 1,
              pageSize: 19,
              pageIndex: 0,
              sorts: [{ name: "createTime", order: "desc" }],
            },
          },
          resDataKey: "result.total",
        },
      },
    ],
    btnConf: {
      add: {},
      view: {},
    },
  }),
  // 告警基础配置
  "rule-engine/Alarm/Config": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-gaojingpeizhi",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
  }),
  // 告警高级配置 - btn(done)
  "rule-engine/Alarm/Configuration": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-gaojinggaojipeizhi",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
  }),
  // 3. 视频中心
  // 流媒体服务 - btn(done)
  "media/Stream": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-liumeitifuwu",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
  }),
  // 国际级联 - btn(done)
  "media/Cascade": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-guojijilian",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
  }),

  // 视频管理 - btn(done)
  "media/Device": JSON.stringify({
    size: "12x1",
    content: [
      {
        componentName: "Statistic",
        colSpan: 8,
        title: "设备数量",
        valueStyle: { paddingTop: "22px" },
        valueConfig: {
          type: "http",
          url: "/api/media/device/count",
          params: {
            method: "GET",
            params: {},
          },
          resDataKey: "result.total",
        },
      },
      {
        componentName: "Statistic",
        colSpan: 8,
        title: "在线数量",
        valueStyle: { paddingTop: "22px" },
        valueConfig: {
          type: "http",
          url: "/api/media/device/count",
          params: {
            method: "GET",
            params: {},
          },
          resDataKey: "result.online",
        },
      },
      {
        componentName: "Statistic",
        colSpan: 8,
        title: "离线数量",
        valueStyle: { paddingTop: "22px" },
        valueConfig: {
          type: "http",
          url: "/api/media/device/count",
          params: {
            method: "GET",
            params: {},
          },
          resDataKey: "result.offline",
        },
      },
    ],
    btnConf: {
      add: {
        mutualType: "link",
        urlCode: "media/Device/Save",
        width: 130,
      },
      view: { width: 130 },
    },
  }),
  "media/SplitScreen": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-fenpingzhanshi1",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
  }),

  // 4. 运维管理
  // 设备接入网关 - btn(done)
  "link/AccessConfig": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-shebeijieruwangguan",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
  }),
  // 协议管理
  "link/Protocol": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-a-xieyiguanli2",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
  }),
  // 组件配置 - 网络组件 - btn(done)
  "link/Type": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-wangluozujian",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
  }),
  // 组件配置 - 证书管理 - btn(done)
  "link/Certificate": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-xieyiguanli",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
  }),

  // 设备升级 - 远程升级
  "device/Firmware": JSON.stringify({
    size: "6x1",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-jieruwangguanbeifen",
        wrapStyle: { paddingTop: "32px", fontSize: "54px" },
      },
    ],
  }),

  // 日志排查 - 日志管理
  Log: JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-rizhiguanli",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
  }),
  // 运维管理 - 故障日志
  fault: JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-guzhangrizhi",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
  }),
  // 5. 系统管理
  // 基础配置 - btn(无)
  "system/Basis": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-zhutipeizhi",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
  }),
  // 用户管理
  "system/User": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-yonghuguanli1",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
  }),
  // 组织管理
  "system/Department": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-zuzhiguanli",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
  }),
  // 角色管理
  "system/Role": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-jiaoseguanli",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
  }),
  // 菜单管理 - btn(done)
  "system/Menu": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-caidanpeizhi",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
  }),
  // 权限管理
  "system/Permission": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-quanxianguanli",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
  }),
  // 关系管理
  "system/Relationship": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-guanxipeizhi",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
  }),
  // 数据源管理
  "system/DataSource": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-shujukuguanli",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
  }),
  // API配置
  "system/Platforms/Setting": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-apiguanli",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
  }),
  // 应用管理 - btn(done)
  "system/Apply": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-yingyongguanli1",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
  }),
  // 边缘设备 - btn(done)
  "edge/Instance": JSON.stringify({
    size: "6x1",
    content: [
      {
        componentName: "Statistic",
        colSpan: 24,
        title: "设备数量",
        valueStyle: { paddingTop: "22px" },
        valueConfig: {
          type: "http",
          url: "/api/device-instance/_query/",
          params: {
            method: "POST",
            data: {
              current: 1,
              pageSize: 19,
              pageIndex: 0,
              terms: [
                {
                  terms: [
                    { termType: "eq", column: "edge", value: "1" },
                    {
                      termType: "eq",
                      column: "osType",
                      value: "X86",
                      type: "and",
                    },
                  ],
                },
              ],
              sorts: [{ name: "createTime", order: "desc" }],
            },
          },
          resDataKey: "result.total",
        },
      },
    ],
    btnConf: {
      add: {
        mutualType: "drawer",
        data: {},
        model: "add",
      },
      view: {},
    },
  }),
  // 数据清洗
  "data-clean/Instance": JSON.stringify({
    size: "6x1",
    content: [
      {
        componentName: "Statistic",
        colSpan: 24,
        title: "清洗规则",
        valueStyle: { paddingTop: "22px" },
        valueConfig: {
          type: "http",
          url: "/dms/api-clean/data-clean-config/query-cleaning-ruleList",
          params: {
            method: "POST",
            data: { pageNo: 1, pageSize: 12 },
          },
          resDataKey: "data.total",
        },
      },
    ],
    btnConf: {
      add: {
        mutualType: "link",
        urlCode: "data-clean/Instance/Save",
      },
      view: {},
    },
  }),
  // 超级设备模型
  "super/Model": JSON.stringify({
    size: "6x1",
    content: [
      {
        componentName: "Statistic",
        colSpan: 24,
        title: "模型数",
        valueStyle: { paddingTop: "22px" },
        valueConfig: {
          type: "http",
          url: "/api/device/product/ex/super/product/_query",
          params: {
            method: "POST",
            data: {
              terms: [
                {
                  terms: [
                    {
                      termType: "eq",
                      column: "superProductType",
                      value: "superProduct",
                    },
                  ],
                },
              ],
              current: 1,
              pageSize: 12,
              pageIndex: 0,
              sorts: [{ name: "createTime", order: "desc" }],
            },
          },
          resDataKey: "result.total",
        },
      },
    ],
    btnConf: {
      add: {
        mutualType: "drawer",
        data: {},
        model: "add",
      },
      view: {},
    },
  }),
  // 超级设备
  "super/Device": JSON.stringify({
    size: "6x1",
    content: [
      {
        componentName: "Statistic",
        colSpan: 24,
        title: "超级设备数",
        valueStyle: { paddingTop: "22px" },
        valueConfig: {
          type: "http",
          url: "/api/super/device/instance/page",
          params: {
            method: "POST",
            data: {
              current: 1,
              pageSize: 12,
              pageIndex: 0,
              terms: [
                {
                  terms: [
                    {
                      termType: "eq",
                      column: "superDeviceType",
                      value: "superDevice",
                      type: "and",
                    },
                  ],
                },
              ],
              sorts: [{ name: "createTime", order: "desc" }],
            },
          },
          resDataKey: "result.total",
        },
      },
    ],
    btnConf: {
      add: {
        mutualType: "drawer",
        data: {},
        model: "add",
      },
      view: {},
    },
  }),
  // 控制器设备接入
  "controller/Access": JSON.stringify({
    size: "6x1",
    content: [
      {
        componentName: "Statistic",
        colSpan: 24,
        title: "协议插件数",
        valueStyle: { paddingTop: "22px" },
        valueConfig: {
          type: "http",
          url: "/api/sofile/queryList",
          params: {
            method: "POST",
            data: {
              current: 1,
              pageSize: 12,
              pageIndex: 0,
              sorts: [{ name: "createTime", order: "desc" }],
            },
          },
          resDataKey: "result.total",
        },
      },
    ],
    btnConf: {
      add: {
        mutualType: "drawer",
        data: {},
        model: "add",
      },
      view: {},
    },
  }),
  // 开鸿控制器
  "controller/Instance": JSON.stringify({
    size: "6x1",
    content: [
      {
        componentName: "Statistic",
        colSpan: 24,
        title: "控制器数量",
        valueStyle: { paddingTop: "8px" },
        valueConfig: {
          type: "http",
          url: "/api/device/instance/regulator/page",
          params: {
            method: "POST",
            data: {
              current: 1,
              pageSize: 12,
              pageIndex: 0,
              terms: [
                {
                  terms: [
                    {
                      termType: "eq",
                      column: "regulator",
                      value: "1",
                      type: "and",
                    },
                  ],
                },
              ],
              sorts: [{ name: "createTime", order: "desc" }],
            },
          },
          resDataKey: "result.total",
        },
      },
      {
        componentName: "Features",
        colSpan: 12,
        value: "子设备",
      },
      {
        componentName: "Features",
        colSpan: 12,
        value: "应用",
      },
    ],
    btnConf: {
      add: {
        mutualType: "drawer",
        data: {},
        model: "add",
      },
      view: {},
    },
  }),
  // 开鸿适配器
  "controller/Adapter": JSON.stringify({
    size: "6x1",
    content: [
      {
        componentName: "Statistic",
        colSpan: 24,
        title: "适配器数量",
        valueStyle: { paddingTop: "8px" },
        valueConfig: {
          type: "http",
          url: "/api/device-instance/_query/",
          params: {
            method: "POST",
            data: {
              current: 1,
              pageSize: 12,
              pageIndex: 0,
              terms: [
                {
                  terms: [
                    {
                      termType: "eq",
                      column: "presetType",
                      value: "adaptor",
                      type: "and",
                    },
                  ],
                },
              ],
              sorts: [{ name: "createTime", order: "desc" }],
            },
          },
          resDataKey: "result.total",
        },
      },
      {
        componentName: "Features",
        colSpan: 12,
        value: "子设备",
      },
    ],
    btnConf: {
      add: {
        mutualType: "drawer",
        data: {},
        model: "add",
      },
      view: {},
    },
  }),
  // ai模型
  "artificial/Instance": JSON.stringify({
    size: "6x1",
    content: [
      {
        componentName: "Statistic",
        colSpan: 24,
        title: "模型数量",
        valueStyle: { paddingTop: "8px" },
        valueConfig: {
          type: "http",
          url: "/api/app/manager/_count",
          params: {
            method: "POST",
            data: {
              current: 1,
              pageSize: 12,
              pageIndex: 0,
              terms: [{ terms: [{ column: "appType", value: "ai" }] }],
              sorts: [{ name: "createTime", order: "desc" }],
            },
          },
          resDataKey: "result",
        },
      },
      {
        componentName: "Features",
        colSpan: 12,
        value: "版本管理",
      },
      {
        componentName: "Features",
        colSpan: 12,
        value: "边缘部署",
      },
    ],
    btnConf: {
      add: {
        mutualType: "drawer",
        data: {},
        model: "add",
      },
      view: {},
    },
  }),
  // 业务系统
  "application/management": JSON.stringify({
    size: "12x1",
    content: [
      {
        componentName: "Statistic",
        colSpan: 12,
        title: "已发布数量",
        valueStyle: { paddingTop: "22px" },
        valueConfig: {
          type: "http",
          url: "/api/atomic-center/biz/getBizNumber",
          params: {
            method: "GET",
            params: {},
          },
          resDataKey: "data.release",
        },
      },
      {
        componentName: "Statistic",
        colSpan: 12,
        title: "未发布数量",
        valueStyle: { paddingTop: "22px" },
        valueConfig: {
          type: "http",
          url: "/api/atomic-center/biz/getBizNumber",
          params: {
            method: "GET",
            params: {},
          },
          resDataKey: "data.draft",
        },
      },
    ],
    btnConf: {
      add: {
        mutualType: "drawer",
        data: {},
        model: "add",
        width: 130,
      },
      view: {
        mutualType: "drawer",
        data: {},
        model: "view",
        width: 130,
      },
    },
  }),
};

export default CardDataMap;
